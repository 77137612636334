<template>
  <v-list-item link title="SupportVision" :to="{name: 'SupportVision'}">
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-cog</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">Support Vision</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuSupportVision",
  props: {
    superAdmin: Boolean
  }
}
</script>

<style scoped>

</style>